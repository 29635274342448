import { ArrowDownOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Image, Layout, Row, Space, Typography } from "antd"
import { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"
import { WhiteLogo } from "../assets/images"
import { Facebook, Naver } from "../assets/svg"

interface IProps {
    geo?: { country?: string, region?: string }
}

export const Footer: FunctionComponent<IProps> = (props) => {
    const { geo } = props
    const navigate = useNavigate()

    return (
        <footer className="footer">
            <Layout.Content style={{ maxWidth: 1600, margin: '0 auto' }}>
                <Row justify="end">
                    <Col {...{ xs: 0, md: 0, lg: 24 }}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <Row align={"middle"}>
                                    <Col span={12}>
                                        <Typography.Link
                                            onClick={() => { navigate("/") }}
                                        >
                                            <Image
                                                src={WhiteLogo}
                                                preview={false}
                                                height={35}
                                                style={{
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        </Typography.Link>
                                    </Col>
                                    <Col span={12} className="sns">
                                        <Flex justify="end" align="center">
                                            <Space>
                                                <Typography.Link href="https://www.facebook.com/thinkingwolf.co.kr/" target="_blank">
                                                    <Facebook />
                                                </Typography.Link>
                                                <Typography.Link href="https://blog.naver.com/thinkingwolf" target="_blank">
                                                    <Naver />
                                                </Typography.Link>
                                            </Space>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row>
                                    <Col span={18}>
                                        <Space size={4} direction="vertical">
                                            <Typography.Text>
                                                <Typography.Text>
                                                    {`접속위치: ${geo?.country} / ${geo?.region}`}
                                                </Typography.Text>
                                            </Typography.Text>
                                            <Typography.Link
                                                href="tel: 025383602"
                                            >
                                                <Typography.Title level={5}>
                                                    +82 02.538.3602
                                                </Typography.Title>
                                            </Typography.Link>
                                            <Typography.Text>
                                                <Typography.Title level={5}>
                                                    contact@thinkingwolf.co.kr
                                                </Typography.Title>
                                            </Typography.Text>
                                            <Typography.Text>서울특별시 강남구 테헤란로 82길 15, 디아이타워 14층</Typography.Text>
                                        </Space>
                                    </Col>
                                    <Col span={6}>
                                        <Flex justify="end" style={{ marginTop: 12, }}>
                                            <Button
                                                ghost
                                                size="large"
                                                type="primary"
                                                onClick={() => {
                                                    window.location.href = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/%E1%84%89%E1%85%A2%E1%86%BC%E1%84%80%E1%85%A1%E1%86%A8%E1%84%92%E1%85%A1%E1%84%82%E1%85%B3%E1%86%AB%E1%84%82%E1%85%B3%E1%86%A8%E1%84%83%E1%85%A2_%E1%84%92%E1%85%AC%E1%84%89%E1%85%A1%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_24%E1%84%82%E1%85%A7%E1%86%AB+2%E1%84%87%E1%85%AE%E1%86%AB%E1%84%80%E1%85%B5_07.01.pdf'
                                                }}
                                            >
                                                <ArrowDownOutlined />
                                                <Typography.Text
                                                    strong
                                                >
                                                    회사소개서 다운로드
                                                </Typography.Text>
                                            </Button>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Typography.Text>copyright © ThinkingWolf. All rights reserved.</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...{ xs: 24, sm: 24, md: 0, lg: 0, xl: 0, xxl: 0 }}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <Row align={"middle"}>
                                    <Col span={12}>
                                        <Typography.Link
                                            onClick={() => {
                                                navigate("/")
                                            }}
                                        >
                                            <Image
                                                src={WhiteLogo}
                                                preview={false}
                                                height={35}
                                                style={{
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        </Typography.Link>
                                    </Col>
                                    <Col span={12} className="sns">
                                        <Flex justify="end" align="center">
                                            <Space>
                                                <Typography.Link href="https://www.facebook.com/thinkingwolf.co.kr/" target="_blank">
                                                    <Facebook />
                                                </Typography.Link>
                                                <Typography.Link href="https://blog.naver.com/thinkingwolf" target="_blank">
                                                    <Naver />
                                                </Typography.Link>
                                            </Space>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Space size={4} direction="vertical">
                                    <Typography.Text>
                                        <Typography.Text>
                                            {`접속위치: ${geo?.country} / ${geo?.region}`}
                                        </Typography.Text>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Typography.Link
                                            href="tel: 025383602"
                                        >
                                            +82 02.538.3602
                                        </Typography.Link>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Typography.Text>
                                            contact@thinkingwolf.co.kr
                                        </Typography.Text>
                                    </Typography.Text>
                                    <Typography.Text>서울특별시 강남구 테헤란로 82길 15, 디아이타워 14층</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Typography.Text>copyright © ThinkingWolf. All rights reserved.</Typography.Text>
                            </Col>
                        </Row>
                        <Col span={24}>
                            <Flex justify="center" style={{ marginTop: 32, }}>
                                <Button
                                    ghost
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                        window.location.href = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/%E1%84%89%E1%85%A2%E1%86%BC%E1%84%80%E1%85%A1%E1%86%A8%E1%84%92%E1%85%A1%E1%84%82%E1%85%B3%E1%86%AB%E1%84%82%E1%85%B3%E1%86%A8%E1%84%83%E1%85%A2_%E1%84%92%E1%85%AC%E1%84%89%E1%85%A1%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_24%E1%84%82%E1%85%A7%E1%86%AB+2%E1%84%87%E1%85%AE%E1%86%AB%E1%84%80%E1%85%B5_07.01.pdf'
                                    }}
                                >
                                    <ArrowDownOutlined />
                                    <Typography.Text
                                        strong
                                    >
                                        회사소개서 다운로드
                                    </Typography.Text>
                                </Button>
                            </Flex>
                        </Col>
                    </Col>
                </Row>
            </Layout.Content>
        </footer>
    )
}