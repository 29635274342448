import { Layout } from "antd"
import { FunctionComponent, ReactNode, useEffect, useState } from "react"
import { MenuType } from "../type/MenuType"
import { Footer } from "./Footer"
import { Navigation } from "./Navigation"

interface IProps {
    menuType?: MenuType
    children?: ReactNode
}

export const MainLayout: FunctionComponent<IProps> = (props: IProps) => {
    const { menuType, children } = props
    const [geo, setGeo] = useState<{ country?: string, region?: string }>()

    const geojs = async () => {
        try {
            const response = await fetch("https://get.geojs.io/v1/ip/geo.json")
            const data = await response.json();

            setGeo({ country: data?.country, region: data?.region })

            if(data?.country_code != 'KR') {
                window.location.href = '/block.html'
            }
        } catch (error) {
            console.error("위치 정보를 가져오는 데 실패했습니다:", error)
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        geojs()
    }, [])

    return (
        <Layout.Content>
            <Navigation menuType={menuType} />
            {children}
            <Footer geo={geo} />
        </Layout.Content>
    )
}